import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueNumber from "vue-number-animation";
import JsonExcel from "vue-json-excel";
import VueApexCharts from "vue-apexcharts";

import VueAnalytics from 'vue-analytics';

// import html2pdf from "html2pdf.js";
// Vue.use(html2pdf);

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import excel from "vue-excel-export";

Vue.use(excel);

Vue.component("downloadExcel", JsonExcel);

Vue.use(VueNumber);

import VNumeric from "vuetify-numeric/vuetify-numeric.umd";
Vue.use(VNumeric);
// import firebase from "firebase"

// const firebaseConfig = {
//   apiKey: "AIzaSyBTHTaIz17Z0IM-EQFuaWvOrlTrP234iF8",
//   authDomain: "applicrel.firebaseapp.com",
//   databaseURL: "https://applicrel-default-rtdb.firebaseio.com",
//   projectId: "applicrel",
//   storageBucket: "applicrel.appspot.com",
//   messagingSenderId: "127267621645",
//   appId: "1:127267621645:web:7b74962d129d5ee3493e44",
//   measurementId: "G-8G2LBDVT9Q"
// };

import "bootstrap";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./registerServiceWorker";

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-252970464-1',
  router
});

// firebase.initializeApp(firebaseConfig),

new Vue({
  router,
  store: store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
