import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Setup
    ngayi: {email:''},
    ye: {
      email: '',
      fullname: '',
      phone: '',
      year_currently_in: '',
      graduation_year: '',
      favorite_class: ''
    },
    test: {
      number1: '',
      number2: '',
      number3: '',
      number4: '',
      number5: ''
  },

    image: "https://firebasestorage.googleapis.com/v0/b/umojasc-79323.appspot.com/o/Unknown_person.jpg?alt=media&token=6c870557-7b88-41cc-98c1-c75a11e02d92",
    
    dejavu: false,

    // erreur
    erreur: "",
    dialogErreur: false,

    // questions
    dialogAnswer: "no",
    dialogQuestion: false,
    dialogDeath: false,
    question: "",

    folder: "",
    // message
    snackBar: false,
    snackBarTitle: "",


    StudentProfile:{},

    idMaker: ""
  },
  mutations: {},
  actions: {},
  modules: {}
});
