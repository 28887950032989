import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Umoja",
    beforeEnter() {
      window.location.href = '../../Home/index.html';
    }
    // component: () => import("../components/Index.vue")
  },
  {
    path: "/house",
    name: "Home",
    component: () => import("../components/Home.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/Login.vue")
  }, {
    path: "/menu",
    name: "Menu",
    component: () => import("../components/Menu.vue")
  }, {
    path: "/startingTest",
    name: "startingTest",
    component: () => import("../components/Home.vue")
  }, {
    path: "/rulesTest",
    name: "rulesTest",
    component: () => import("../components/Rules.vue")
  }, {
    path: "/endTest",
    name: "EndTest",
    beforeEnter() {
      window.location.href = '../../Home/endTest.html';
    }
  }, {
    path: "/testUmoja",
    name: "TestUmoja",
    component: () => import("../components/TestUmoja.vue")
  },
  {
    path: "/congrats",
    name: "Congrats",
    beforeEnter() {
      window.location.href = '../../Home/congrats.html';
    }
    // component: () => import("../components/Index.vue")
  }, 
  {
    path: "/alreadyApplied",
    name: "AlreadyApplied",
    beforeEnter() {
      window.location.href = '../../Home/alreadyApplied.html';
    }
    // component: () => import("../components/Index.vue")
  }, 
  {
    path: "/cheatingPage",
    name: "CheatingPage",
    beforeEnter() {
      window.location.href = '../../Home/cheatingPage.html';
    }
    // component: () => import("../components/Index.vue")
  }, {
    path: "/teams",
    name: "Teams",
    component: () => import("../components/Desktop/Team/Team.vue")
  }, {
    path: "/schools",
    name: "Schools",
    component: () => import("../components/Desktop/Team/School.vue")
  }, {
    path: "/students",
    name: "Students",
    component: () => import("../components/Desktop/Team/Student.vue")
  }, {
    path: "/quiz",
    name: "Quiz",
    component: () => import("../components/Desktop/Team/Quiz.vue")
  }, {
    path: "/quizUmoja",
    name: "QuizUmoja",
    component: () => import("../components/QuizUmoja.vue")
  }, {
    path: "/student_profile",
    name: "Student Profile",
    component: () => import("../components/Desktop/Team/StudentProfile.vue")
  }, {
    path: "/signupbefore",
    name: "Sign Up Before",
    component: () => import("../components/Signupbefore.vue")
  }, {
    path: "/signup",
    name: "Sign Up",
    component: () => import("../components/Signup.vue")
  }, {
    path: "/profile",
    name: "Profile",
    component: () => import("../components/Desktop/Team/Profile.vue")
  }, {
    path: "/apply",
    name: "Apply",
    component: () => import("../components/Apply.vue")
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("../components/Home.vue")
  // },

];

const router = new VueRouter({
  routes
});

export default router;