<template>
  <v-app>
    <v-main class="p-0">
      <!-- style="background-color:#f5f6f8;" -->
      <div>  
        <router-view></router-view>
      </div>
    </v-main>

    <v-snackbar v-model="$store.state.snackBar" color="primary">
      {{ $store.state.snackBarTitle }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="$store.state.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="$store.state.dialogErreur" max-width="450">
      <v-card>
        <v-card-title class="title red--text font-weight-normal">
          Error:
        </v-card-title>

        <v-card-text>
          <h1 class="title black--text">
            {{ $store.state.erreur }}
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            color="red"
            text
            @click="$store.state.dialogErreur = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$store.state.dialogQuestion" max-width="450">
      <v-card>
        <v-card-title class="title warning--text font-weight-normal">
          Warning:
        </v-card-title>

        <v-card-text>
          <h1 class="title black--text">
            {{ $store.state.question }}
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            color="red"
            text
            @click="
              $store.state.dialogAnswer = 'non';
              $store.state.dialogQuestion = false;
            "
          >
            Cancel
          </v-btn>

          <v-btn
            small
            color="green"
            text
            @click="
              $store.state.dialogAnswer = 'yes';
              $store.state.dialogQuestion = false;
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChat" persistent max-width="100%">
      <v-card class="vh-100">
        <v-card-title class="title red--text font-weight-normal">
          Error:
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <div class="text-center">
            <img
              :src="require('./images/myLogo.png')"
              class="w-25"
            /><br /><br />
            <h5 class="font-weight-bold">
              Votre compte a été bloquer à cause des activités illégales.
            </h5>
            <br />
            <v-btn href="tel:3195193008" color="red" class="white--text">
              Contacter le consultant
            </v-btn>
            <v-divider></v-divider>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
// import db from "./db.js";
// import firebase from "firebase";
// import sound from "./sounds/wrong.wav";

export default {
  data: () => ({
    items: [],
    family: [],
    death: [],
    dialogChat: false,
    audio: null
  }),
  
  
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(200px);
  opacity: 0;
}
</style>
